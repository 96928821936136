.Light {
    aspect-ratio: 1/1;
    border-radius: 1em;
    overflow: hidden;
}

.Light.on {
     background: #ddd
}

.Light.off {
    background: #222
}
